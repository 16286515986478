import { createStore } from "vuex";
import http from "@/http";
import router from "@/router";
import i18n, { setI18nLanguage } from "@/i18n";
import frameBus from "@/framebus";

let user = null;
try {
  user = JSON.parse(localStorage.getItem("user"));
} catch (e) {
  console.warn("user parse error", e);
}

let apartmentsCompare = {};

try {
  apartmentsCompare = JSON.parse(localStorage.getItem("apartmentsCompare"));
  if (!apartmentsCompare) {
    apartmentsCompare = {};
  }
} catch (e) {
  console.warn("apartmentsCompare parse error", e);
}

let apartmentFavs = [];

try {
  apartmentFavs = JSON.parse(localStorage.getItem("apartmentFavs"));
  if (!apartmentFavs) {
    apartmentFavs = [];
  }
} catch (e) {
  console.warn("apartmentFavs parse error", e);
}

const filterObject = (obj, callback) => {
  if(Array.isArray(obj)){
    return obj.filter(callback);
  }
  if (obj) {
    return Object.fromEntries(
      Object.entries(obj).filter(([key, val]) => callback(val, key))
    );
  }
  return obj;
};

const store = createStore({
  state() {
    return {
      accessToken: localStorage.getItem("token"),
      user: user,
      settings: JSON.parse(localStorage.getItem("settings")),
      filter: null,
      mainInit: false,
      showControls: false,
      projects: JSON.parse(localStorage.getItem("projects")),
      currentProjectId: null,
      currentProductId: null,
      currentFloorId: null,
      currentContentId: null,
      currentPageName: null,
      modals: {
        contact: false,
        project: false,
        share: false,
        accessibility: false,
        apartmentRegistration: false,
      },
      modalsData: {
        shareDialogApartment: null,
        shareDialogFloor: null,
        shareDialogType: "apartment",
      },
      products: {},

      apartmentContent: null,
      apartmentsPageCurrent: null,
      animationEnded: false,
      floors: {},
      floorContent: null,
      floorsPageCurrent: null,
      entrepreneurs: [],
      architects: [],
      pages: {},
      apartmentsCompare,
      apartmentFavs,
      screen: {
        isMobile: false,
        isTablet: false,
        lowResLaptop: false,
      },
      mobileMenuOpen: false,
      projectsPaneOpen: false,
      stagesPaneShow: true,
      ctrl360: false,
      contentPages: {},
      imageCanvas: {
        src: null,
      },
      floorMode: false,
      modelUrl: null,
      hdrUrl: null,
      apartmentOnly: false,
      mobileButtonsHeight: 0,
      mobileHeaderHeight: 0,
      bottomBarHeight: 0,
      globalLoading: 0,
      weatherCity: null,
      menu: [],
      locale: localStorage.getItem("current_locale") || "he",
      sideMenu: {
        showTitles: false,
        toggleMethod: 'mouse-hover'
      },
      colors: {
        primary_lighterer: "#00fff0",
        primary_lighter: "#00E2D5",
        primary_light: "#00D4C8",
        primary: "#00C3B8",
        primary_dark: "#019F95",
        primary_darker: "#005C57",
        primary_darkerer: "#1f3e3f",
      },
      isModelApp: false,
      showContactButtons: true,
      showFavsButton: true,
      modelsReady: false,
      // showRegistrationProcess: true,
    };
  },
  getters: {
    projects({ projects }) {
      /*if (projects) {
        return filterObject(projects, (p) => p.language === locale);
      }*/
      return projects;
    },
    buildings({ buildings }) {
      /*if (buildings) {
        return filterObject(buildings, (p) => p.language === locale);
      }*/
      return buildings;
    },
    currentProject({ projects, currentProjectId }) {
      if(projects && currentProjectId){
        if(Array.isArray(projects)){
          return projects.find(p => p.id === currentProjectId)
        }
        if (projects[currentProjectId]) {
          return projects[currentProjectId];
        }
      }
      return null;
    },
    currentProjectId({ currentProjectId }) {
      return currentProjectId;
    },
    contactModal({ modals }) {
      return modals.contact;
    },
    apartmentRegistrationModal({ modals }) {
      return modals.apartmentRegistration;
    },
    projectModal({ modals }) {
      return modals.project;
    },
    accessibilityModal({ modals }) {
      return modals.accessibility;
    },
    mainBg({ currentProjectId, projects }) {
      const currentProject = projects && Array.isArray(projects) ? projects.find((p) => p.id === currentProjectId) : null;
      if (
        currentProjectId &&
        projects &&
        projects[currentProjectId] &&
        projects[currentProjectId].main_bg
      ) {
        return projects[currentProjectId].main_bg;
      }
      if (currentProject && currentProject.main_bg) {
        return currentProject.main_bg;
      }
      return null;
    },
    products({ products, currentProjectId }) {
      if (products && currentProjectId) {
        return filterObject(
          products,
          (p) =>
            p.project_id === currentProjectId || (p.project_ids && p.project_ids.includes(currentProjectId))
        );
      }
      return products;
    },
    apartmentsCompare({ apartmentsCompare }) {
      return apartmentsCompare;
    },
    floorMode({ floorMode }) {
      return floorMode;
    },
    apartmentFavs({ apartmentFavs }) {
      return apartmentFavs;
    },
    apartmentContentType({ apartmentContent }) {
      return apartmentContent && apartmentContent.acf_fc_layout
        ? apartmentContent.acf_fc_layout
        : null;
    },
    apartmentContent({ apartmentContent }) {
      return apartmentContent;
    },
    floorContentType({ floorContent }) {
      return floorContent && floorContent.acf_fc_layout
        ? floorContent.acf_fc_layout
        : null;
    },
    floorContent({ floorContent }) {
      return floorContent;
    },
    apartmentsPageCurrent({ apartmentsPageCurrent }) {
      return apartmentsPageCurrent;
    },
    animationEnded({ animationEnded }) {
      return animationEnded;
    },
    floorsPageCurrent({ floorsPageCurrent }) {
      return floorsPageCurrent;
    },
    isMobile({ screen }) {
      return screen.isMobile;
    },
    isTablet({ screen }) {
      return screen.isTablet;
    },
    lowResLaptop({ screen }) {
      return screen.lowResLaptop;
    },
    isDesktop({ screen }) {
      return !screen.isTablet && !screen.isMobile;
    },
    mobileMenuOpen({ mobileMenuOpen }) {
      return mobileMenuOpen;
    },
    projectsPaneOpen({ projectsPaneOpen }) {
      return projectsPaneOpen;
    },
    ctrl360({ ctrl360 }) {
      return ctrl360;
    },
    stagesPaneShow({ stagesPaneShow }) {
      return stagesPaneShow;
    },
    mainInit({ mainInit }) {
      return mainInit;
    },
    settings({ settings }) {
      return settings;
    },
    contentPages({ contentPages }) {
      return contentPages;
    },
    currentContentId({ currentContentId }) {
      return currentContentId;
    },
    currentPageName({ currentPageName }) {
      return currentPageName;
    },
    contentPage({ currentContentId, contentPages }) {
      return contentPages[currentContentId];
    },
    contentPageByName({ currentPageName, pages }) {
      return pages[currentPageName];
    },
    shareDialogOpen({ modals }) {
      return modals.share;
    },
    imageCanvas({ imageCanvas }) {
      return imageCanvas;
    },
    apartmentOnly({ apartmentOnly }) {
      return apartmentOnly;
    },
    layout({ settings }) {
      return settings.layout || "bottom-bar";
    },
    isSideMenu({ settings }, { isDesktop, isTablet }) {
      return (
        (isDesktop || isTablet) &&
        settings &&
        settings.layout &&
        settings.layout === "side-menu"
      );
    },
    hasBottomBar({ settings }, { isMobile, isSideMenu }) {
      return isMobile || !settings || !settings.layout || !isSideMenu;
    },
    isTouchScreen() {
      return "ontouchstart" in window;
    },
    filterOptions({ settings }) {
      if (
        settings &&
        settings.apartments_filter &&
        settings.apartments_filter.options
      ) {
        return settings.apartments_filter.options;
      }
      return null;
    },
    apartmentDataView({ settings }) {
      if (settings && settings.apartments_data_view) {
        return settings.apartments_data_view;
      }
      return null;
    },
    floorFilterOptions({ settings }) {
      if (
        settings &&
        settings.floors_filter &&
        settings.floors_filter.options
      ) {
        return settings.floors_filter.options;
      }
      return null;
    },
    apartmentsFilter({ settings }) {
      if (settings && settings.apartments_filter) {
        return settings.apartments_filter;
      }
      return null;
    },
    compareEnabled(state, { filterOptions }) {
      if (filterOptions && filterOptions.compare_enabled) {
        return filterOptions.compare_enabled;
      }
      return null;
    },
    showRelatedApartments(state, { filterOptions }){
      if (filterOptions && filterOptions.show_related_apartments) {
          return filterOptions.show_related_apartments;
      }
      return false;
    },
    showContactButtons(state) {
      return state.showContactButtons;
    },
    modelUrl(state) {
      return state.modelUrl;
    },
    hdrUrl(state) {
      return state.hdrUrl;
    },
    showFavsButton(state) {
      return state.showFavsButton;
    },
    apartmentPageEnabled(state, { filterOptions }) {
      return filterOptions && filterOptions.apartment_page_enabled;
    },
    apartmentThumbnailDisplay(state, { filterOptions }) {
      return filterOptions && filterOptions.apartment_thumbnail_display;
    },
    mobileButtonsHeight({ screen, mobileButtonsHeight }) {
      if (screen.isMobile) {
        return mobileButtonsHeight;
      }
      return 0;
    },
    mobileHeaderHeight({ mobileHeaderHeight }) {
      return mobileHeaderHeight;
    },
    bottomBarHeight({ bottomBarHeight }) {
      return bottomBarHeight;
    },
    globalLoading({ globalLoading }) {
      return globalLoading;
    },
    galleriesSettings({ settings }) {
      if (settings && settings.galleries) {
        return settings.galleries;
      }
      return null;
    },
    filter({ filter }) {
      return filter;
    },
    contactDetails({ settings }) {
      if (
        settings &&
        settings.contact_form &&
        settings.contact_form.contact_details
      ) {
        return settings.contact_form.contact_details;
      }
      return null;
    },
    signatureIsMandatory({ settings }) {
      if (
        settings &&
        settings.contact_form &&
        settings.contact_form.contact_details
      ) {
        return settings.contact_form.signature_is_mandatory;
      }
      return false;
    },
    mobileContactFormButtonLocation({ settings }) {
      if (
        settings &&
        settings.contact_form &&
        settings.contact_form.mobile_contact_form_button_location
      ) {
        return settings.contact_form.mobile_contact_form_button_location;
      }
      return null;
    },
    hasWhatsappButton(state, { contactDetails }) {
      return (
        contactDetails &&
        contactDetails.whatsapp &&
        contactDetails.whatsapp.phone
      );
    },
    showFloatingContactButton(
      state,
      { isMobile, mobileButtons, mobileContactFormButtonLocation }
    ) {
      return (
        !isMobile ||
        (isMobile &&
          (!mobileButtons || mobileButtons.length === 0) &&
          mobileContactFormButtonLocation === "floating")
      );
    },
    // isShowRegistrationProcess(state){

    //     return state.showRegistrationProcess
    // },
    mobileButtons({ settings }) {
      if (settings && settings.mobile_buttons) {
        return settings.mobile_buttons;
      }
      return null;
    },
    weatherWidget({ settings }) {
      if (settings && settings.weather_widget) {
        return settings.weather_widget;
      }
      return null;
    },
    weatherCity({ weatherCity }) {
      return weatherCity;
    },
    menu({ menu }) {
      return menu;
    },
    menuFromSettings({ settings, locale }, { isMobile }) {
      if (settings.menus && settings.menus.length > 0) {
        const menu = settings.menus.find((m) => {
          if (m.locations && m.locations.length > 1) {
            if (isMobile) {
              return (
                m.locations.findIndex((l) => l.includes("mobile")) !== -1 &&
                (!m.language || m.language === locale)
              );
            } else {
              return (
                m.locations.findIndex((l) => l.includes("primary")) !== -1 &&
                (!m.language || m.language === locale)
              );
            }
          }
          return !m.language || m.language === locale;
        });
        if (menu) {
          return menu;
        }
        if (settings.menus[0]) {
          return settings.menus[0];
        }
      } else if (settings.menu) {
        return settings.menu;
      }
      return null;
    },
    locale({ locale }) {
      return locale;
    },
    shareDialogApartment({ modalsData }) {
      return modalsData.shareDialogApartment;
    },
    shareDialogFloor({ modalsData }) {
      return modalsData.shareDialogFloor;
    },
    showHome3DModel({ settings }) {
      if (settings && settings.show_home_3d_model) {
        return settings.show_home_3d_model;
      }
      return null;
    },
    sortingMethod(state, {apartmentDataView}){
      if(apartmentDataView && apartmentDataView.sorting_method){
          return apartmentDataView.sorting_method
      }
      return 	'floor'
    },
    sortingDirection(state, {apartmentDataView}){
        if(apartmentDataView && apartmentDataView.direction){
            return apartmentDataView.direction
        }
        return 	'ASC'
    },
      apartmentFieldsOrder(state, {settings}){
        if(settings && settings.apartment_fields_order){
            return settings.apartment_fields_order;
        }
        return null;
    },
    apartmentTableFieldsOrder(state, {apartmentFieldsOrder}){
        if(apartmentFieldsOrder && apartmentFieldsOrder.table){
            return apartmentFieldsOrder.table;
        }
        return null;
    },
    apartmentPageFieldsOrder(state, {apartmentFieldsOrder}){
        if(apartmentFieldsOrder && apartmentFieldsOrder.page){
            return apartmentFieldsOrder.page;
        }
        return null;
    },
    apartmentBlockFieldsOrder(state, {apartmentFieldsOrder}){
        if(apartmentFieldsOrder && apartmentFieldsOrder.block){
            return apartmentFieldsOrder.block;
        }
        return null;
    },
    showApartments3DModel({ settings }) {
      if (settings && settings.show_apartments_3d_model) {
        return settings.show_apartments_3d_model;
      }
      return null;
    },
    showApartment3DModel({ settings }) {
      if (settings && settings.show_apartment_3d_model) {
        return settings.show_apartment_3d_model;
      }
      return null;
    },
    sideMenuShowTitles({ sideMenu }) {
      if (sideMenu) {
        return sideMenu.showTitles;
      }
      return false;
    },
    sideMenuToggleMethod({sideMenu}){
      if(sideMenu && sideMenu.toggleMethod){
        return sideMenu.toggleMethod
      }
      return 'click'
    },
    floors({ floors, currentProjectId }) {
      if (floors) {
        return filterObject(
          floors,
          (p) =>
            p.project_ids &&
            p.project_ids.includes(currentProjectId)
        );
      }
      return floors;
    },
    colors({ colors }) {
      return colors;
    },
    floorPageEnabled(state, { floorFilterOptions }) {
      return floorFilterOptions && floorFilterOptions.floor_page_enabled;
    },
    shareDialogType({ modalsData }) {
      return modalsData.shareDialogType;
    },
    accessToken({ accessToken }) {
      return accessToken;
    },
    isModelApp({ isModelApp }) {
      return isModelApp;
    },
    showApartmentShareButton({ settings }) {
      if (
        settings &&
        typeof settings.show_apartment_share_button !== "undefined"
      ) {
        return settings.show_apartment_share_button;
      }
      return true;
    },
    apartmentContactButton({ settings }, {apartmentEnterButtonText}) {
      if (
        settings &&
        settings.contact_form &&
        settings.contact_form.apartment_contact_button
      ) {
        return settings.contact_form.apartment_contact_button;
      }
      return {
        title: apartmentEnterButtonText,
        show: true,
        translate: true,
      };
    },
    apartmentEnterButtonText({ settings }) {
      if (settings && settings.apartments_data_view && settings.apartments_data_view.apartment_enter_button_text) {
        return settings.apartments_data_view.apartment_enter_button_text;
      }
      return 'כניסה לדירה';
    },
    apartmentsFilterTitle({ settings }) {
      if (settings && settings.apartments_data_view && settings.apartments_data_view.apartments_filter_title) {
        return settings.apartments_data_view.apartments_filter_title;
      }
      return 'כניסה לדירות';
    },
    modelsReady({ modelsReady }) {
      return modelsReady;
    }
  },
  mutations: {
    setProjects(state, projects) {
      localStorage.setItem("projects", JSON.stringify(projects));
      state.projects = projects;
    },
    selectProject(state, projectId) {
      // check if projectId is number
      if (projectId && isNaN(projectId)) {
        state.currentProjectId = projectId;
      }else{
        state.currentProjectId = parseInt(projectId);
      }
    },
    mainInit(state) {
    state.mainInit = true;
    },
    showControls(state) {
      state.showControls = true;
    },
    setFloorMode(state, active) {
      console.log(active)
      state.floorMode = active;
    },
    setUser(state, user) {
      state.user = user;
      if (user) {
        localStorage.setItem("user", JSON.stringify(user));
      } else {
        localStorage.removeItem("user");
      }
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
      if (accessToken) {
        localStorage.setItem("token", accessToken);
      } else {
        localStorage.removeItem("token");
      }
    },
    toggleContactModal(state, status) {
      if (typeof status !== "undefined") {
        state.modals.contact = status;
      } else {
        state.modals.contact = !state.modals.contact;
      }
    },

    toggleApartmentRegistrationModal(state, status) {
      if (typeof status !== "undefined") {
        state.modals.apartmentRegistration = status;
      } else {
        state.modals.apartmentRegistration =
          !state.modals.apartmentRegistration;
      }
    },
    toggleSideMenuContactButtons(state, status) {
      if (typeof status !== "undefined") {
        state.showContactButtons = status;
      } else {
        state.showContactButtons = !state.showContactButtons;
      }
    },
    toggleSideMenuFavButton(state, status) {
      if (typeof status !== "undefined") {
        state.showFavsButton = status;
      } else {
        state.showFavsButton = !state.showFavsButton;
      }
    },
    toggleProjectModal(state, status) {
      if (typeof status !== "undefined") {
        state.modals.project = status;
      } else {
        state.modals.project = !state.modals.project;
      }
    },
    toggleAccessibilityModal(state, status) {
      if (typeof status !== "undefined") {
        state.modals.accessibility = status;
      } else {
        state.modals.accessibility = !state.modals.accessibility;
      }
    },
    settings(state, settings) {
      state.settings = settings;
      if (settings) {
        localStorage.setItem("settings", JSON.stringify(settings));
        if(settings.desktop_menu_toggle && state.sideMenu){
          state.sideMenu.toggleMethod = settings.desktop_menu_toggle;
        }
      }
    },
    products(state, products) {
      if (!state.products) {
        state.products = {};
      }
      state.products = products;
      // const appIds = Object.keys(products);
      // for (let index = 0; index < appIds.length; index++) {
      //   if (
      //     products &&
      //     appIds &&
      //     products[appIds[index]] &&
      //     products[appIds[index]].type_building &&
      //     products[appIds[index]].type_building["3d_model_file"]
      //   ) {
      //     state.modelUrl =
      //       products[appIds[index]].type_building["3d_model_file"];
      //     state.hdrUrl =
      //       products[appIds[index]].type_building["3d_model_hdr_file"];
      //     break;
      //   }
      // }
    },
    deleteProduct(state, id) {
      if (state.products && state.products[id]) {
        const products = JSON.parse(JSON.stringify(state.products));
        delete products[id];
        state.products = products;
      }
    },
    floors(state, floors) {
      if (!state.floors) {
        state.floors = {};
      }
      state.floors = floors;
    },
    product(state, product) {
      if (!state.products) {
        state.products = {};
      }
      if(Array.isArray(state.products)){
        const index = state.products.findIndex((p) => p.id === product.id);
        if (index !== -1) {
          state.products[index] = product;
        } else {
          state.products.push(product);
        }
      }else{
        state.products[product.id] = product;
      }
    },
    floor(state, floor) {
      if (!state.floors) {
        state.floors = {};
      }
      state.floors[floor.id] = floor;
    },
    currentProductId(state, id) {
      state.currentProductId = id;
    },
    currentFloorId(state, id) {
      state.currentFloorId = id;
    },
    apartmentContent(state, apartmentContent) {
      state.apartmentContent = apartmentContent;
    },
    apartmentsPageCurrent(state, apartmentsPageCurrent) {
      state.apartmentsPageCurrent = apartmentsPageCurrent;
    },
    animationEnded(state, animationEnded) {
      console.log("animationEnded", animationEnded);
      state.animationEnded = animationEnded;
    },
    floorsPageCurrent(state, floorsPageCurrent) {
      state.floorsPageCurrent = floorsPageCurrent;
    },
    floorContent(state, floorContent) {
      state.floorContent = floorContent;
    },
    entrepreneurs(state, entrepreneurs) {
      state.entrepreneurs = entrepreneurs;
    },
    architects(state, architects) {
      state.architects = architects;
    },
    pages(state, pages) {
      state.pages = pages;
    },
    page(state, { pageName, page }) {
      state.pages[pageName] = page;
    },
    apartmentsCompare(state, apartmentsCompare) {
      state.apartmentsCompare = apartmentsCompare;
      localStorage.setItem(
        "apartmentsCompare",
        JSON.stringify(state.apartmentsCompare)
      );
    },
    apartmentsCompareRemove(state, id) {
      if (state.apartmentsCompare[id]) {
        delete state.apartmentsCompare[id];
        localStorage.setItem(
          "apartmentsCompare",
          JSON.stringify(state.apartmentsCompare)
        );
      }
    },
    addApartmentFav(state, aptId) {
      const index = state.apartmentFavs.findIndex((id) => id === aptId);
      if (index === -1) {
        state.apartmentFavs.push(aptId);
      }
      localStorage.setItem(
        "apartmentFavs",
        JSON.stringify(state.apartmentFavs)
      );
    },
    removeApartmentFav(state, aptId) {
      const index = state.apartmentFavs.findIndex((id) => id === aptId);
      if (index > -1) {
        state.apartmentFavs.splice(index, 1);
      }
      localStorage.setItem(
        "apartmentFavs",
        JSON.stringify(state.apartmentFavs)
      );
    },
    isMobile(state, isMobile) {
      state.screen.isMobile = isMobile;
    },
    isTablet(state, isTablet) {
      state.screen.isTablet = isTablet;
    },
    lowResLaptop(state, lowResLaptop) {
      state.screen.lowResLaptop = lowResLaptop;
    },
    mobileMenuOpen(state, mobileMenuOpen) {
      state.mobileMenuOpen = mobileMenuOpen;
    },
    projectsPaneOpen(state, projectsPaneOpen) {
      state.projectsPaneOpen = projectsPaneOpen;
    },
    projectModal(state, status) {
      state.modals.project = status;
    },
    ctrl360(state, ctrl360) {
      state.ctrl360 = ctrl360;
    },
    stagesPaneShow(state, stagesPaneShow) {
      state.stagesPaneShow = stagesPaneShow;
    },
    currentContentId(state, currentContentId) {
      state.currentContentId = currentContentId;
    },
    currentPageName(state, currentPageName) {
      state.currentPageName = currentPageName;
    },
    contentPage(state, content) {
      state.contentPages[content.ID || content.id] = content;
      //localStorage.setItem('contentPages', JSON.stringify(state.contentPages))
    },
    shareDialogOpen(state, status) {
      state.modals.share = status;
    },
    imageCanvas(state, imageCanvas) {
      state.imageCanvas = imageCanvas;
    },
    apartmentOnly(state, apartmentOnly) {
      state.apartmentOnly = apartmentOnly;
    },
    layout(state, layout) {
      state.settings.layout = layout;
    },
    mobileButtonsHeight(state, mobileButtonsHeight) {
      state.mobileButtonsHeight = mobileButtonsHeight;
    },
    mobileHeaderHeight(state, mobileHeaderHeight) {
      state.mobileHeaderHeight = mobileHeaderHeight;
    },
    bottomBarHeight(state, bottomBarHeight) {
      state.bottomBarHeight = bottomBarHeight;
    },
    globalLoading(state, globalLoading) {
      state.globalLoading = globalLoading;
    },
    globalLoadingAttract(state) {
      state.globalLoading++;
    },
    globalLoadingSubtract(state) {
      if (state.globalLoading > 0) {
        state.globalLoading--;
      }
    },
    filter(state, filter) {
      state.filter = filter;
    },
    weatherCity(state, weatherCity) {
      state.weatherCity = weatherCity;
    },
    menu(state, menu) {
      state.menu = menu;
    },
    locale(state, locale) {
      state.locale = locale;
    },
    shareDialogApartment(state, shareDialogApartment) {
      state.modalsData.shareDialogApartment = shareDialogApartment;
    },
    shareDialogFloor(state, shareDialogFloor) {
      state.modalsData.shareDialogFloor = shareDialogFloor;
    },
    sideMenuShowTitles(state, showTitles) {
      state.sideMenu.showTitles = showTitles;
    },
    sideMenuToggleMethod(state, toggleMethod){
      state.sideMenu.toggleMethod = toggleMethod
    },
    colors(state, colors) {
      state.colors = colors;
    },
    shareDialogType(state, shareDialogType) {
      state.modalsData.shareDialogType = shareDialogType;
    },
    isModelApp(state, isModelApp) {
      state.isModelApp = isModelApp;
    },
    modelsReady(state, modelsReady) {
      state.modelsReady = modelsReady;
    },
  },
  actions: {
    initBranding({ getters, commit, dispatch }) {
      if (
        getters.settings.contact_form?.contact_bar_static &&
        getters.isDesktop
      ) {
        commit("toggleContactModal", true);
      }
      if (getters.settings.primary_color) {
        dispatch("setPrimaryColorAndShades", getters.settings.primary_color);
      }
    },
    async loadSettings({ getters, commit, dispatch }) {
      const { data } = await http.get("settings", {
        params: { projectId: getters.currentProjectId },
      });
      if (data.settings) {
        const { settings } = data;
        commit("settings", settings);
        dispatch("initBranding");
        // TODO: load google font: https://github.com/typekit/webfontloader
      }
    },
    setPrimaryColorAndShades({ commit }, color) {
      const pSBC = (p, c0, c1, l) => {
        let r,
          g,
          b,
          P,
          f,
          t,
          h,
          i = parseInt,
          m = Math.round,
          a = typeof c1 == "string";
        if (
          typeof p != "number" ||
          p < -1 ||
          p > 1 ||
          typeof c0 != "string" ||
          (c0[0] !== "r" && c0[0] !== "#") ||
          (c1 && !a)
        )
          return null;
        if (!this.pSBCr)
          this.pSBCr = (d) => {
            let n = d.length,
              x = {};
            if (n > 9) {
              ([r, g, b, a] = d = d.split(",")), (n = d.length);
              if (n < 3 || n > 4) return null;
              (x.r = i(r[3] === "a" ? r.slice(5) : r.slice(4))),
                (x.g = i(g)),
                (x.b = i(b)),
                (x.a = a ? parseFloat(a) : -1);
            } else {
              if (n === 8 || n === 6 || n < 4) return null;
              if (n < 6)
                d =
                  "#" +
                  d[1] +
                  d[1] +
                  d[2] +
                  d[2] +
                  d[3] +
                  d[3] +
                  (n > 4 ? d[4] + d[4] : "");
              d = i(d.slice(1), 16);
              if (n === 9 || n === 5)
                (x.r = (d >> 24) & 255),
                  (x.g = (d >> 16) & 255),
                  (x.b = (d >> 8) & 255),
                  (x.a = m((d & 255) / 0.255) / 1000);
              else
                (x.r = d >> 16),
                  (x.g = (d >> 8) & 255),
                  (x.b = d & 255),
                  (x.a = -1);
            }
            return x;
          };
        (h = c0.length > 9),
          (h = a ? (c1.length > 9 ? true : c1 === "c" ? !h : false) : h),
          (f = this.pSBCr(c0)),
          (P = p < 0),
          (t =
            c1 && c1 !== "c"
              ? this.pSBCr(c1)
              : P
              ? { r: 0, g: 0, b: 0, a: -1 }
              : { r: 255, g: 255, b: 255, a: -1 }),
          (p = P ? p * -1 : p),
          (P = 1 - p);
        if (!f || !t) return null;
        if (l)
          (r = m(P * f.r + p * t.r)),
            (g = m(P * f.g + p * t.g)),
            (b = m(P * f.b + p * t.b));
        else
          (r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)),
            (g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)),
            (b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5));
        (a = f.a),
          (t = t.a),
          (f = a >= 0 || t >= 0),
          (a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0);
        if (h)
          return (
            "rgb" +
            (f ? "a(" : "(") +
            r +
            "," +
            g +
            "," +
            b +
            (f ? "," + m(a * 1000) / 1000 : "") +
            ")"
          );
        else
          return (
            "#" +
            (
              4294967296 +
              r * 16777216 +
              g * 65536 +
              b * 256 +
              (f ? m(a * 255) : 0)
            )
              .toString(16)
              .slice(1, f ? undefined : -2)
          );
      };

      const range = 0.3;
      const step = 0.1;
      const colors = {};
      for (let i = -range; i <= range; i = i + step) {
        const fixed = parseFloat(i.toFixed(2));
        const shade = pSBC(fixed, color);
        switch (fixed) {
          case -0.3:
            document.documentElement.style.setProperty(
              "--primary-lighterer",
              shade
            );
            colors["primary_lighterer"] = shade;
            break;
          case -0.2:
            document.documentElement.style.setProperty(
              "--primary-lighter",
              shade
            );
            colors["primary_lighter"] = shade;
            break;
          case -0.1:
            document.documentElement.style.setProperty(
              "--primary-light",
              shade
            );
            colors["primary_light"] = shade;
            break;
          case 0:
            document.documentElement.style.setProperty("--primary", shade);
            colors["primary"] = shade;
            break;
          case 0.1:
            document.documentElement.style.setProperty("--primary-dark", shade);
            colors["primary_dark"] = shade;
            break;
          case 0.2:
            document.documentElement.style.setProperty(
              "--primary-darker",
              shade
            );
            colors["primary_darker"] = shade;
            break;
          case 0.3:
            document.documentElement.style.setProperty(
              "--primary-darkerer",
              shade
            );
            colors["primary_darkerer"] = shade;
            break;
        }
      }
      commit("colors", colors);
    },
    async saveProjectSettings({ getters }, newSettings) {
      try {
        const res = await http.post(
          `projects/` + getters.currentProjectId,
          {
            default_view: newSettings,
          },
        );
        console.log(res.data)
      } catch (error) {
        if (http.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          // Handle other errors
          console.error("Request error:", error);
        }
      } finally {
        // setSaveStatus("Save")
      }
    },
    async login({commit}, credentials){
      commit('globalLoadingAttract')
      try {
          const {data} = await http.post('token', credentials)
          commit('globalLoadingSubtract')
          const {user, token} = data
          if(user && token){
              commit('setAccessToken', token)
              commit('setUser', user)
              return {user, token}
          }
      }catch (e){
          console.warn(e)
      }
      return {user: null, token: null}
  },
    async logout({ commit }) {
      commit("setAccessToken", null);
      commit("setUser", null);
      await router.push({ name: "login" });
    },
    validateToken({ state, dispatch }) {
      if (state.accessToken) {
        return new Promise((resolve, reject) => {
          http.get("profile").then((response) => {
            if (response.status === 200) {
              resolve();
            } else {
              dispatch("logout");
              reject();
            }
          });
        });
      }
      return Promise.reject(false);
    },
    setFirstProject({ commit, getters }) {
      console.log({setFirstProject: getters.projects})
      if (getters.projects) {
        if(Array.isArray(getters.projects) && getters.projects[0]){
          const project = getters.projects[0];
          if(project){
            console.log('setFirstProject',{project})
            commit('selectProject', project.id)
          }
        }else{
          const keys = Object.keys(getters.projects);
          if (keys && keys.length) {
            commit("selectProject", keys[0]);
          }
        }
      }
    },
    async preloadAll({ commit, dispatch, getters, state }) {
      commit("globalLoadingAttract");
      const { data } = await http.get("preload_all", {
        params: { force: true, dc: true, fp_lang: getters.locale },
      });
      commit("globalLoadingSubtract");
      if (data.settings) {
        commit("settings", data.settings);
        dispatch("initBranding");
        if(data.settings.lang){
          commit("locale", data.settings.lang);
          setI18nLanguage(i18n, data.settings.lang);
        }
      }
      if (data.projects) {
        commit("setProjects", data.projects);
        dispatch("setFirstProject");
      }
      if (data.products) {
        commit("products", data.products);
      }
      if (data.floors) {
        commit("floors", data.floors);
      }
      if (data.posts) {
        data.posts.forEach((p) => {
          commit("page", { pageName: p.post_name, page: p });
          commit("contentPage", p);
        });
        console.log({
          contentPages: state.contentPages
        })
      }
      if (data.filter) {
        commit("filter", data.filter);
      }
      dispatch("buildMenu");
    },
    async loadProjects({ state, commit }) {
      commit("globalLoadingAttract");
      const { data } = await http.get("projects");
      commit("globalLoadingSubtract");
      if (data.projects) {
        commit("setProjects", data.projects);
        if (!state.currentProjectId) {
          if(Array.isArray(data.projects) && data.projects[0]){
            const project = data.projects[0];
            if(project){
              commit('selectProject', project.id)
            }
          }else{
            const keys = Object.keys(data.projects);
            if (keys && keys.length) {
              commit("selectProject", keys[0]);
            }
          }
        }
      }
    },
    async loadProduct({ commit, state, getters }, id) {
      commit("currentProductId", id);
      if (state.products) {
        if(state.products[id]){
          return state.products[id];
        }
        if(Array.isArray(state.products)){
          const p = state.products.find(p => p.id === id);
          if(p){
            return p;
          }
        }
      }
      commit("globalLoadingAttract");
      const { data } = await http.get(`products/${id}`, {
        params: {
          'fp_lang': getters.locale
        }
      });
      commit("globalLoadingSubtract");
      if (data.product) {
        commit("product", data.product);
        return data.product;
      }
      return null;
    },
    async loadFloor({ commit, state }, id) {
      commit("currentFloorId", id);
      if (state.floors && state.floors[id]) {
        return state.floors[id];
      }
      commit("globalLoadingAttract");
      const { data } = await http.get(`floors/${id}`);
      commit("globalLoadingSubtract");
      if (data.floor) {
        commit("floor", data.floor);
        return data.floor;
      }
      return null;
    },
    async searchProducts(
      { state, commit, getters },
      { filter, force, allProjects } = {}
    ) {
      const products = getters.products;
      if (
        !allProjects &&
        !force &&
        !filter &&
        !!products &&
        Object.keys(products).length > 0
      ) {
        return Object.entries(products)
          .map((e) => e[1])
          .sort((p1, p2) => p1["fp-sort"] - p2["fp-sort"]);
      }
      commit("globalLoadingAttract");
      const params = { ...filter };
      if (!allProjects) {
        params.project = state.currentProjectId;
      }
      const { data } = await http.get("products", { params });
      commit("globalLoadingSubtract");
      if (data.products) {
        commit("products", data.products);
        return Object.entries(data.products)
          .map((e) => e[1])
          .sort((p1, p2) => p1["fp-sort"] - p2["fp-sort"]);
      }
      return null;
    },
    async saveProducts({ commit, getters }, { products }) {
      commit("globalLoadingAttract");
      let productsToSave = products ? products : getters.products;
      const productsTemp = Object.keys(productsToSave).map((key) => {
        const product = JSON.parse(JSON.stringify(productsToSave[key]));
        delete product.buttons;
        delete product.more_details;
        delete product.type_building;
        delete product.type_plot;
        delete product.type_quarter;
        return product;
      });
      const { data } = await http.post("products", productsTemp);
      commit("globalLoadingSubtract");
      if (!products && data.products) {
        commit("products", data.products);
        return data.products;
      }
      return null;
    },
    async deleteProduct({ commit }, { productId }) {
      if (productId) {
        commit("globalLoadingAttract");
        const { data } = await http.delete(`products/${productId}`);
        commit("globalLoadingSubtract");
        if (data && data.success) {
          commit("deleteProduct", productId);
          return true;
        }
      }
      return null;
    },
    async searchFloors({ state, commit, getters }, { filter, force } = {}) {
      const floors = getters.floors;
      if (!force && !filter && !!floors && Object.keys(floors).length > 0) {
        return floors;
      }
      commit("globalLoadingAttract");
      const { data } = await http.get("floors", {
        params: { project: state.currentProjectId, ...filter },
      });
      commit("globalLoadingSubtract");
      if (data.floors) {
        commit("floors", data.floors);
        return data.floors;
      }
      return null;
    },
    async entrepreneursAndArchitects({ state, commit }) {
      commit("entrepreneurs", []);
      commit("architects", []);
      const { data } = await http.get("entrepreneurs-and-architects", {
        params: { project: state.currentProjectId },
      });
      let response = {};
      if (data.entrepreneurs) {
        commit("entrepreneurs", data.entrepreneurs);
        response.entrepreneurs = data.entrepreneurs;
      }

      if (data.architects) {
        commit("architects", data.architects);
        response.architects = data.architects;
      }
      return response;
    },
    async loadContent({ getters, state, commit }, { postId, type }) {
      if (!type) {
        type = "content";
      }
      commit("currentContentId", postId);
      if (getters.contentPage) {
        return getters.contentPage;
      }
      commit("globalLoadingAttract");
      const { data } = await http.get(
        `project/${state.currentProjectId}/${type}/${postId}`
      );
      commit("globalLoadingSubtract");
      let response = {};
      if (data.post) {
        commit("contentPage", data.post);
      }
      return response;
    },
    async loadPage({ commit, getters }, { pageName, postType }) {
      commit("currentPageName", pageName);
      if (getters.contentPageByName) {
        return getters.contentPageByName;
      }
      const { data, status } = await http.get(postType || "content", {
        params: { post_name: pageName },
      });
      if (status === 200 && data.post) {
        commit("page", { pageName, page: data.post });
        return data.post;
      }
      return false;
    },
    toggleFavs({ state, commit }, aptId) {
      if (state.apartmentFavs.findIndex((id) => id === aptId) === -1) {
        commit("addApartmentFav", aptId);
      } else {
        commit("removeApartmentFav", aptId);
      }
    },
    updateMediaQuery({ commit }, { mobile, tablet, lowResLaptop }) {
      if (typeof mobile !== "undefined") {
        commit("isMobile", mobile);
      }
      if (typeof tablet !== "undefined") {
        commit("isTablet", tablet);
      }
      if (typeof lowResLaptop !== "undefined") {
        commit("lowResLaptop", lowResLaptop);
      }
    },
    toggleMobileMenu({ commit, state }) {
      if (state.mobileMenuOpen) {
        commit("mobileMenuOpen", false);
      } else {
        commit("mobileMenuOpen", true);
      }
    },
    toggleProjectsPane({ commit, state }) {
      if (state.projectsPaneOpen) {
        commit("projectsPaneOpen", false);
      } else {
        commit("projectsPaneOpen", true);
        commit("projectModal", false);
      }
    },
    openImageEditor({ commit }, src) {
      commit("imageCanvas", { src });
    },
    closeImageEditor({ commit }) {
      commit("imageCanvas", { src: null });
    },
    async changeProject({ commit, getters }, { id }) {
      if (getters.currentProjectId && getters.settings.project_id !== id) {
        commit("selectProject", id);
        //await dispatch("loadSettings");
      } else {
        commit("selectProject", id);
      }
    },
    async loadWeatherCity({ commit, getters }) {
      try {
        const { data } = await http.get("weather", {
          params: { cityId: getters.weatherWidget.city_for_weather },
        });
        const { city } = data;
        if (city) {
          commit("weatherCity", city);
          return city;
        }
      } catch (e) {
        console.warn("no weather data");
      }
      return null;
    },
    closeMobileMenu({ commit, getters }) {
      if (getters.isMobile && getters.mobileMenuOpen) {
        commit("mobileMenuOpen", false);
      }
      if (getters.projectsPaneOpen) {
        commit("projectsPaneOpen", false);
      }
    },
    async setLocale({ commit, dispatch }, locale) {
      commit("locale", locale);
      localStorage.setItem("current_locale", locale);
      setI18nLanguage(i18n, locale);
      await dispatch("preloadAll");
      dispatch("setFirstProject");
      dispatch("buildMenu");
      frameBus.emit('message', {
        action: 'reload-model-iframe'
      });
      await router.replace("/");
    },
    buildMenu({ commit, getters }) {
      const menu = getters.menuFromSettings;
      if (menu && (menu.items || menu[0])) {
        commit(
          "menu",
          (menu.items || menu).map((menuItem) => {
            if (menuItem.link === "home") {
              menuItem.link = "/";
            } else if (menuItem.link === "project") {
              menuItem.link = "";
            }
            return menuItem;
          })
        );
      } else {
        commit("menu", [
          {
            label: i18n.global.t("בית"),
            link: "/",
            class: "menu-item btn-360",
          },
          {
            label: i18n.global.t("הפרוייקט"),
            link: ``,
            class: "menu-item",
          },
          {
            label: i18n.global.t("הדירות"),
            link: `apartments`,
            class: "menu-item",
          },
          {
            label: i18n.global.t("הסביבה"),
            link: `area`,
            class: "menu-item",
          },
          {
            label: i18n.global.t("היזם"),
            link: `entrepreneurs-architects`,
            class: "menu-item",
          },
        ]);
      }
    },
    shareDialogToggle({ commit }, { show, apartment, floor }) {
      commit("shareDialogOpen", show);
      if (show) {
        if (apartment) {
          commit("shareDialogApartment", apartment);
          commit("shareDialogType", "apartment");
        } else if (floor) {
          commit("shareDialogFloor", floor);
          commit("shareDialogType", "floor");
        }
      } else {
        commit("shareDialogApartment", null);
        commit("shareDialogFloor", null);
        commit("shareDialogType", "apartment");
      }
    },
  },
});

export default store;
